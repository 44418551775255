import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { IconLookup, IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAngellist,
  faBluesky,
  faFacebook,
  faImdb,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTiktok,
  faVk,
  faWeibo,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { faAngry } from '@fortawesome/free-regular-svg-icons';

import { ENVIRONMENT } from '@environment';
import { getMovieGenres, getMovieTags, Movie, SocialNetworkLink } from '@usheru-hff/shared/domain';
import { TrackingService } from '@usheru-hff/shared/utils-tracking';

/**
 * Component that show share options to share the app or movies in social platforms.
 *
 * //TODO barbeitoalex propostal - organice life cycle methods. Split in specific method to improve docs.
 * //TODO barbeitoalex propostal - refractor name to something like "movie-share".
 */
@Component({
  selector: 'ush-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialLinksComponent {
  farBluesky = faBluesky as IconLookup;
  private readonly env = inject(ENVIRONMENT);

  faFacebook = faFacebook;
  /**
   * Items with the links information.
   *
   * //TODO barbeitoalex propostal - the title is not translate and is used only in tracking
   * //TODO barbeitoalex propostal - add the types
   */
  socialLinks: { icon?: IconLookup; iconProp: IconProp; iconType; title; to; name }[] = [];

  /**
   * Used to generate {@link socialLinks}.
   *
   * //TODO barbeitoalex propostal - put the generate process in the setter of this
   */

  _socialNetworks: SocialNetworkLink[];
  private readonly showBlueSky = this.env?.showBlueSky;

  get socialNetworks() {
    return this._socialNetworks;
  }

  @Input()
  set socialNetworks(snls: SocialNetworkLink[]) {
    this._socialNetworks = snls;
    this.socialLinks = snls
      ?.map(snl => {
        const isBluesky = snl.socialNetwork.name.toLowerCase() === 'bluesky';
        // if it's bluesky and showBlueSky is false, return undefined to skip
        if (isBluesky && !this.showBlueSky) {
          return undefined;
        }
        let iconType: 'fa' | 'image' = 'fa';
        let iconProp!: IconProp;
        let icon!: IconLookup;
        switch (true) {
          case this.iconsImage.includes(snl.socialNetwork.name):
            iconType = 'image';
            break;
          default:
            iconProp = this.getIconProp(snl.socialNetwork.name);
            icon = this.getIcon(snl.socialNetwork.name);
        }
        return {
          icon,
          iconType,
          iconProp,
          title: `public.footer.social.${snl.socialNetwork.name}`,
          to: snl.socialNetwork.baseUrl + snl.account,
          name: snl.socialNetwork.name
        };
      })
      .filter(Boolean);
  }

  /**
   * @deprecard Don't found any use
   */
  @Input()
  movie?: Movie;

  /**
   * Names of font-awesome icons
   */
  faIcons: string[] = [
    'Bluesky',
    'youtube',
    'facebook',
    'instagram',
    'twitter',
    'angellist',
    'linkedin',
    'vk',
    'vimeo',
    'pinterest',
    'imdb',
    'TikTok',
    'weibo'
  ];

  /**
   * Name of image source icons.
   */
  iconsImage: string[] = ['cinando', 'letterboxd'];

  constructor(
    private trackingService: TrackingService,
    private cd: ChangeDetectorRef
  ) {}

  getIcon(name: string): IconLookup | undefined {
    switch (name) {
      case 'Bluesky':
        return faBluesky as IconLookup;
      case 'youtube':
        return faYoutube as IconLookup;
      case 'facebook':
        return faFacebook as IconLookup;
      case 'instagram':
        return faInstagram as IconLookup;
      case 'twitter':
        return faXTwitter as IconLookup;
      case 'angellist':
        return faAngellist as IconLookup;
      case 'linkedin':
        return faLinkedin as IconLookup;
      case 'vk':
        return faVk as IconLookup;
      case 'pinterest':
        return faPinterest as IconLookup;
      case 'imdb':
        return faImdb as IconLookup;
      case 'tiktok':
      case 'TikTok':
        return faTiktok as IconLookup;
      case 'weibo':
        return faWeibo as IconLookup;
    }
    console.error(`Icon ${name} is not found, please review it.`);
    return faAngry as IconLookup;
  }

  getIconProp(name: string): IconProp {
    switch (name) {
      case 'Bluesky':
        return 'bluesky';
      case 'youtube':
        return 'youtube';
      case 'facebook':
        return 'facebook';
      case 'twitter':
        return 'twitter';
      case 'instagram':
        return 'instagram';
      case 'angellist':
        return 'angellist';
      case 'linkedin':
        return 'linkedin';
      case 'vk':
        return 'vk';
      case 'vimeo':
        return 'vimeo';
      case 'pinterest':
        return 'pinterest';
      case 'imdb':
        return 'imdb';
      case 'TikTok':
        return 'tiktok';
      case 'weibo':
        return 'weibo';
      default:
        return null;
    }
  }

  /**
   * Gets image icons from name.
   * @param name
   * @returns
   */
  getIconImage(name: string): string {
    switch (name) {
      case 'cinando':
        return 'https://cdnstatic.usheru.com/img/social-icons/cinando.png';
      case 'letterboxd':
        return 'https://cdnstatic.usheru.com/img/social-icons/letterboxd-white.png';
      default:
        return null;
    }
  }

  /**
   * When the user click a social icons add a tracking.
   * @param sl
   */
  onSocialLinkClicked(sl): void {
    if (this.movie) {
      this.trackingService.trackEvent({
        action: `MovieSocialLink`,
        properties: {
          category: 'MovieDetail',
          label: sl.to,
          idMovie: this.movie.id,
          imdb: this.movie?.imdb,
          movie: this.movie.title,
          tagsMovie: getMovieTags(this.movie),
          genresMovie: getMovieGenres(this.movie),
          socialPlatform: sl.icon.iconName
        }
      });
    } else {
      // used in the footer element
      this.trackingService.trackEvent({
        action: `ChannelSocialLink`,
        properties: {
          category: 'Footer',
          label: sl.to,
          socialPlatform: sl.icon.iconName
        }
      });
    }
  }
}
