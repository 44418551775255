<ng-container *ngIf="socialLinks">
  <div class="social-links">
    <ul>
      <li *ngFor="let sl of socialLinks" [ngClass]="sl.name">
        <a class="social-link" [ngClass]="sl.name" [href]="sl.to" (click)="onSocialLinkClicked(sl)" target="_blank" [attr.data-type]="sl.iconType || 'none'">
          <ng-container *ngIf="sl.iconType === 'fa'">
            <fa-icon [icon]="sl.icon" size="lg"></fa-icon>
          </ng-container>
          <ng-container *ngIf="sl.iconType === 'image'">
            <img [src]="sl.icon || { height: 25 }" [alt]="sl + 'icon'" />
          </ng-container>
        </a>
      </li>
    </ul>
  </div>
</ng-container>
